import React, { useState, useEffect, useRef } from "react"
import axios from 'axios'
import { API } from "./../../config/api"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../../components/layout"
import HeaderPages from "../../components/header-pages"
import Seo from "../../components/seo"
import moment from "moment/moment"
import BlogCard from "../../components/cards/blog-card"

const TermConditionDetail = ({ params }) => {
    const param = params['id']
    const [Data, setData] = useState()
    const [otherData, setOtherData] = useState()

    console.log("FLAG DATA: ", param)
    useEffect(() => {
        function fetchDataNews() {
            const response = axios.get(
                `${API.URL}/api/v1/news/news-detail?news_id=${param}`
            ).then(response => {
                if (response?.data?.status) {
                    setData(response.data.data)
                    console.log("DATA: ", response?.data);
                } else {
                    console.warning("DATA: ", response?.data);
                }
            }).catch(err => {
                console.log(err)
            })
            return response
        }
        function fetchOtherData() {
            const response = axios
                .get(`${API.URL}/api/v1/news/view-more?size=3&page=1`)
                .then(response => {
                    setOtherData(response?.data?.data)
                })
                .catch(err => {
                    console.log(err)
                })
            return response
        }

        fetchDataNews()
        fetchOtherData()
    }, [param])

    return (
        <>
            <Layout pageTitle="Detail Artikel | Klabbelanja">
                <Seo title="Detail Artikel | Klabbelanja" />
                <HeaderPages />
                <section className="blog-style-one blog-page" id="blog" style={{ marginRight: 15, marginLeft: 15 }}>
                    <Container>
                        <Row>
                            <div style={{ maxHeight: '500px', width: '100%' }}>
                                <img src={Data?.path} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                            </div>
                        </Row>
                        <div style={{ height: 20 }} />
                        <Row>
                            <h3 style={{ color: 'black' }}>{Data?.title}</h3>
                        </Row>
                        <p style={{ color: 'black', padding: 3, borderRadius: 5, backgroundColor: 'lightgray', display: 'inline' }}>{moment(Data?.created_at).format('DD MMMM YYYY')}</p>
                        <div style={{ height: 20 }} />
                        {/* <Row xs={1} sm={1} md={1} lg={1}> */}
                        <div style={{ width: '80%', color: 'black' }}>
                            <div dangerouslySetInnerHTML={{ __html: Data?.descriptions }} />
                        </div>
                        {/* </Row> */}
                        <div style={{ height: 50 }} />
                        <Row>
                            {otherData?.map(
                                (
                                    { path, id, author, date, comments, title, text, link },
                                    index
                                ) => (
                                    <Col
                                        key={`Article terbaru & promo menarik-${index}`}
                                        lg={4}
                                        md={6}
                                        sm={12}
                                    >
                                        <BlogCard
                                            image={path}
                                            title={title}
                                            link={`/detail-article/${id}`}
                                        />
                                    </Col>
                                )
                            )}
                        </Row>
                    </Container>
                </section>
            </Layout>
        </>
    )
}

export default TermConditionDetail
